import Button from '@mui/material/Button';
import logo from '../../assets/logo.png'
import { Box } from '@mui/system';
import Stack from '@mui/material/Stack';
import img2 from '../../assets/imgs/304.png'
import img3 from '../../assets/imgs/305.png'
import car from '../../assets/imgs/carro.png'
import backgroundSemiFooter from '../../assets/imgs/backgroundHome.jpg'
import img1 from '../../assets/fotosOrcamento1.png'
import img2SemiFooter from '../../assets/imgs/autoserv2.jpg'
import img3SemiFooter from '../../assets/imgs/autoserv3.jpg'
import img4SemiFooter from '../../assets/imgs/autoserv4.jpg'
import { Header, Menu, Main, Box as Bx, Text, TextInput, FileInput, TextArea, Button as Btn, ResponsiveContext, Select, DateInput } from 'grommet';
import { Link } from "react-router-dom";
import api from '../../services/api';
import React from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import { FeedInstagram3Row } from '../../Components/feed/feedInstagram';
import Swal from 'sweetalert2'
import axios from 'axios';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import hamb from '../../assets/hamburger.png'

import './orcStyle.css';
import './orcStyles.scss';

const Orc = () => {
    const style = {
        navbarAtual: {
            backgroundColor: 'rgb(243 194 102)', '&:hover': { backgroundColor: 'rgb(243 194 102)', opacity: [0.9, 0.8, 0.7], }, height: '5vh',
            fontFamily: 'Syne-Bold',

            fontSize: 15, whiteSpace: 'nowrap'
        },
        navbarSecundario: {
            backgroundColor: 'transparent', '&:hover': { backgroundColor: 'rgb(243 194 102)', opacity: [0.9, 0.8, 0.7], }, height: '5vh',
            fontFamily: 'Syne-Bold',
            fontSize: 5, whiteSpace: 'nowrap'
        },
        navbarAtualTelefone: { backgroundColor: 'rgb(243 194 102)', '&:hover': { backgroundColor: 'rgb(243 194 102)', opacity: [0.9, 0.8, 0.7], }, height: '5vh', fontFamily: 'PricedownBl', fontSize: 30, width: '100%' },
        navbarTelefone: { backgroundColor: 'gray', '&:hover': { backgroundColor: 'rgb(243 194 102)', opacity: [0.9, 0.8, 0.7], }, height: '5vh', fontFamily: 'PricedownBl', fontSize: 30, width: '100%' },
        form: { width: '30%' },
        formSmall: { width: '80%', marginBottom: '10%' }
    }

    const [unome, setUnome] = React.useState('');
    const [usobrenome, setUsobrenome] = React.useState('');
    const [utelefone, setUtelefone] = React.useState('');
    const [uemail, setUemail] = React.useState('');
    const [uProduto, setUProduto] = React.useState('');
    const [uQuantidade, setUQuantidade] = React.useState('');
    const [uArte, setUArte] = React.useState('');
    const [uDate, setUDate] = React.useState('');
    const [uTamanho, setUTamanho] = React.useState('');
    const [uLargura, setULargura] = React.useState('');
    const [uMaterial, setUMaterial] = React.useState('');
    const [uEsmalte, setUEsmalte] = React.useState('');
    const [uCor, setUCor] = React.useState('');
    const [u3d, setU3d] = React.useState('');
    const [uBorda, setUBorda] = React.useState('');
    const [uGravado, setUGravado] = React.useState('');
    const [uEmbalagem, setUEmbalagem] = React.useState('');
    const [obs, setObs] = React.useState('');

    const [orcamentos, setOrcamentos] = React.useState(null);

    const [uEndereco_cep, setUEnderecoCep] = React.useState('');
    const [uEndereco_rua, setUEnderecoRua] = React.useState('');
    const [uEndereco_numero, setUEnderecoNumero] = React.useState('');
    const [uEndereco_complemento, setUEnderecoComplemento] = React.useState('Sem complemento');
    const [uEndereco_bairro, setUEnderecoBairro] = React.useState('');
    const [uEndereco_cidade, setUEnderecoCidade] = React.useState('');
    const [uEndereco_estado, setUEnderecoEstado] = React.useState('');




    const [foto1, setFoto1] = React.useState(null);
    const [foto2, setFoto2] = React.useState(null);

    const handleInfos = (refresh) => {

        let fd = new FormData()
        fd.append("frente", foto1);
        fd.append("verso", foto2);

        var datatime = new Date();
        let hour = datatime;
        var dia = String(datatime.getDate()).padStart(2, '0');
        var mes = String(datatime.getMonth() + 1).padStart(2, '0');
        var ano = String(datatime.getFullYear());
        var fecha = dia + '/' + mes + '/' + ano;

        var anoE = uDate.substr(0, 4);
        var mesE = uDate.substr(5, 2);
        var diaE = uDate.substr(8, 2);
        var fechaE = diaE + '/' + mesE + '/' + anoE;


        let data = {
            nome: unome,
            sobrenome: usobrenome,
            telefone: utelefone,
            email: uemail,
            produto: uProduto,
            quantidade: uQuantidade,
            arte: uArte,
            tamanho: uTamanho,
            largura: uLargura,
            esmalte: uEsmalte,
            material: uMaterial,
            cor: uCor,
            m3d: u3d,
            borda: uBorda,
            gravado: uGravado,
            embalagem: uEmbalagem,
            data: fecha,
            horas: hour,
            dataEntrega: fechaE,
            obs: obs,
            enderecoEntrega_cep: uEndereco_cep,
            enderecoEntrega_logradouro: uEndereco_rua,
            enderecoEntrega_numero: uEndereco_numero,
            enderecoEntrega_completomento: uEndereco_complemento,
            enderecoEntrega_bairro: uEndereco_complemento,
            enderecoEntrega_estado: uEndereco_estado,
            enderecoEntrega_cidade: uEndereco_cidade,
            idcot: orcamentos,

        }
        // console.log(data);
        // return 1;

        if (orcamentos !== '') {
            console.clear();
            console.log(data);
            api.post('enviar-informacoes', data)
                .then((res) => {
                    console.clear();
                    console.log(res);
                    if (foto1) {
                        let idorctemp = res.data.id_orcamento;
                        api.post('enviar-informacoes-foto/' + res.data.id_orcamento, fd)
                            .then(res => {
                                console.clear();
                                console.table(data);
                                console.table(res.data)

                                if (refresh) {
                                    Swal.fire({
                                        icon: 'success',
                                        title: 'Enviado com sucesso',
                                        text: 'Muito obrigado pela confiança na Chilecoins! Seu Orçamento sera enviado em breve',
                                        confirmButtonText: 'Ok'
                                    }).then(() => {
                                        window.location.reload();
                                    })
                                }
                                else {
                                    // api.get('receber-mail/' + idorctemp);

                                    Swal.fire({
                                        title: 'Desejar limpar os dados do produto?',
                                        icon: 'question',
                                        showCancelButton: true,
                                        confirmButtonColor: '#3085d6',
                                        cancelButtonColor: '#d33',
                                        confirmButtonText: 'Limpar',
                                        cancelButtonText: 'Cancelar'
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            clearHalf();
                                        }
                                    })
                                    // setOrcamentos(res.data.cotacao);
                                    // clearHalf();
                                }

                            }).catch(e => {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Erro',
                                    text: 'Algo deu errado ao enviar as fotos, entraremos em contato com você',
                                })
                            }
                            )

                    }
                    else {
                        if (refresh) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Enviado com sucesso',
                                text: 'Muito obrigado pela confiança na Chilecoins! Seu Orçamento sera enviado em breve',
                                confirmButtonText: 'Ok'
                                // footer: '<a href="">Why do I have this issue?</a>'
                            }).then(() => {
                                // api.get('receber-mail/' + idorctemp).then(res => {
                                window.location.reload();
                                // })
                            })
                        }
                        else {
                            // api.get('receber-mail/' + idorctemp);

                            Swal.fire({
                                title: 'Desejar limpar os dados do produto?',
                                icon: 'question',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Limpar',
                                cancelButtonText: 'Cancelar'
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    clearHalf();
                                }
                            })
                        }
                    }
                })
                .catch(e => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Erro',
                        text: 'Algo deu errado ao enviar o orçamento, por favor revise seus dados',
                        // footer: '<a href="">Why do I have this issue?</a>'
                    })
                    console.log(e)
                }
                )
        }
        else {
        }

        return 1;


    }

    const OpenModalOption = () => {
        var re = /\S+@\S+\.\S+/;
        if (re.test(uemail)) {
            if (uQuantidade >= 50) {
                Swal.fire({
                    title: 'Orcamento completo',
                    text: "Seu orçamento esta completo. Se quiser adicionar mais um produto, presione NOVO ou presione ENVIAR para continuar.",
                    icon: "success",
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'ENVIAR',
                    confirmButtonText: 'NOVO'
                }).then((result) => {
                    if (result.isConfirmed) {
                        handleInfos(false);
                    }
                    else {
                        handleInfos(true);
                    }
                })
            }
            else {
                Swal.fire({
                    icon: 'info',
                    title: 'Atenção',
                    text: 'Para quantidades inferiores a 50 por favor entre em contato por email ou whatsapp',
                    footer: '<a href="mailto:<chilecoins>orcamento@chilecoins.com.br?subject="Estou com duvidas"" style="margin-right: 2%;">orcamento@chilecoins.com.br</a> <span style="margin-right: 2%;"> ou </span> <a href="https://wa.me/5521988314926?text=Ola+chilecoins+quero+fazer+um+or%C3%A7amento+especial%2C+estou+no+aguardo"> Whatsapp </a>'
                })
                return 1;
            }
        }
        else {
            alert('email invalido')
        }
    }

    const clearHalf = async () => {
        setClean(true);
        setFoto1(null);
        setFoto2(null);
        setUQuantidade('');
        setUTamanho('');
        setULargura('');
        // setUEndereco('');
        setUMaterial('');
        setUEsmalte('');
        setUCor('');
        setU3d('');
        setUBorda('');
        setUGravado('');
        setObs('');
        await setUEmbalagem('');
        setClean(false);
    }
    const size = React.useContext(ResponsiveContext);
    const [sidebar, setSidebar] = React.useState(false);

    const [clenaing, setClean] = React.useState(false);

    const PesquisarCep = (valor) => {
        var cep = valor.replace(/\D/g, '');

        if (cep != "") {
            var validacep = /^[0-9]{8}$/;
            if (validacep.test(cep)) {
                axios.get('https://viacep.com.br/ws/' + cep + '/json').then(res => {
                    console.log(res.data)
                    setUEnderecoCep(res.data.cep);
                    setUEnderecoRua(res.data.logradouro);
                    setUEnderecoBairro(res.data.bairro);
                    setUEnderecoCidade(res.data.localidade);
                    setUEnderecoEstado(res.data.uf);
                })
            } //end if.
            else {
                alert("Formato de CEP inválido.");
            }
        }
    };




    const HeaderGeral = () => {
        return (
            <Header pad={{ left: 'xlarge', right: '0px' }} style={{ backgroundColor: 'black' }} >
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                // mt={1}
                // mb={1}
                >
                    <img src={logo} style={{ height: '6vh' }} />

                </Stack>

                <h4 style={{ color: 'white', position: 'absolute', textAlign: 'center', marginLeft: '40%' }}> Orçamento </h4>

                <div style={{ color: 'white', backgroundColor: '#f5500c', height: '110%', paddingLeft: '2%', paddingRight: '2%' }}>
                    <h1 > <img src={hamb} alt="" style={{ height: '1.8vh' }} /> </h1>
                </div>

            </Header>)
    }

    const HeaderBody = () => {
        return (
            // <<<<<<< HEAD
            <Box style={{ marginLeft: '17.5%', marginTop: '5%', marginBottom: '4%' }}>
                {/* =======
            <Box style={{ marginLeft: '17%', marginTop: '3%' }}>
>>>>>>> 4e83f6896a77960ac82a9ecb2c31e9a0f73d10c4 */}
                <h1>
                    Orçamento de moeda personalizada
                </h1>
                <span>
                    Siga o passo a passo para a construção da sua moeda, preencha todos os dados e no final envie para a gente
                </span>

            </Box>
        );
    }


    const FooterBody = () => {
        return (<Stack justifyContent="center" alignItems="center" >
            {/* <FeedInstagram3Row /> */}
        </Stack>)
    }

    const [loadingUseEffect, setLdUse] = React.useState(true);

    const [useEffectDados, setUseEffectDados] = React.useState([])

    React.useEffect(() => {
        api.get('orcamentos-use-effect').then(res => {
            console.log(res.data);
            setUseEffectDados(res.data);
            setLdUse(false);
        })
    }, [])

    const [value, setValue] = React.useState('');

    const verificarData = (value) => {

        const now = new Date(); // Data de hoje
        const past = new Date(value); // Outra data no passado
        const diff = Math.abs(now.getTime() - past.getTime()); // Subtrai uma data pela outra
        const days = Math.ceil(diff / (1000 * 60 * 60 * 24)); // Divide o total pelo total de milisegundos correspondentes a 1 dia. (1000 milisegundos = 1 segundo).


        if (days <= 45) {
            Swal.fire({
                icon: 'info',
                title: 'Atenção',
                text: 'Para pedidos de orçamento para menos de 45 dias por favor entre em contato conosco por email ou WhatsApp',
                footer: '<a href="mailto:<chilecoins>orcamento@chilecoins.com.br?subject="Estou com duvidas"" style="margin-right: 2%;">orcamento@chilecoins.com.br</a> <span style="margin-right: 2%;"> ou </span> <a href="https://wa.me/5521988314926?text=Ola+chilecoins+quero+fazer+um+or%C3%A7amento+especial%2C+estou+no+aguardo"> Whatsapp </a>'
            })

        }
        else {
            setUDate(value);
        }


    }

    const [selectEsmalte, setSelectEsmalte] = React.useState(false);
    const [selectBorda, setSelectBorda] = React.useState(false);

    const handleBlurQty = () => {
        if (uQuantidade < 50) {
            setUQuantidade(50);
        }
    }
    return (
        <Main style={{ backgroundColor: 'white' }}>
            <HeaderGeral />


            <HeaderBody />

            <Stack justifyContent="center" alignItems={size !== 'small' ? ('none') : 'center'} direction={size !== 'small' ? ('row') : 'column'} spacing={size !== 'small' ? 10 : 2} mt='2%' >

                <Bx style={size !== 'small' ? style.form : style.formSmall} gap='medium'>
                    {/* <<<<<<< HEAD */}
                    <Text style={{ fontFamily: 'Poppins', fontSize: 30, color: '#f5500c' }}>Dados do Usúario</Text>



                    {/* =======
                    <Text style={{ fontFamily: 'Syne-Bold', fontSize: 30, color: '#f5500c' }}>Dados do Usúario</Text>
>>>>>>> 4e83f6896a77960ac82a9ecb2c31e9a0f73d10c4 */}

                    <Bx direction='row' style={{ width: '100%' }} gap='small'>
                        <TextInput className='Rectángulo_3_copia'
                            // value={unome}
                            onChange={event => setUnome(event.target.value)}
                            placeholder="Nome"

                        />
                        <TextInput
                            // value={usobrenome}
                            placeholder="Sobrenome"
                            onChange={event => setUsobrenome(event.target.value)}

                        />
                    </Bx>

                    {/* <TextInput
                        //  value={utelefone} 
                        onChange={event => setUtelefone(event.target.value)} placeholder="Telefone" /> */}

                    <PhoneInput
                        country='br'
                        regions={'south-america'}
                        // style={{ wdith: '100px' }}
                        onChange={number => { setUtelefone(number) }}
                        inputStyle={{ fontFamily: 'Avenir-Next' }}
                    />
                    <TextInput value={uemail} onChange={event => setUemail(event.target.value)} placeholder="Email" type="email" />

                    <TextInput placeholder='Cep'
                        onChange={event => setUEnderecoCep(event.target.value)}
                        onBlur={(e) => PesquisarCep(e.target.value)}
                    // value={uEndereco_cep}
                    />

                    <TextInput placeholder='Logradouro'
                        onChange={event => setUEnderecoRua(event.target.value)}
                        value={uEndereco_rua}
                    />

                    <TextInput placeholder='Número'
                        onChange={event => setUEnderecoNumero(event.target.value)}
                    // value={uEndereco_numero}
                    />

                    <TextInput placeholder='Complemento'
                        onChange={event => setUEnderecoComplemento(event.target.value)}
                    // value={uEndereco_complemento}
                    />

                    <TextInput placeholder='Bairro'
                        onChange={event => setUEnderecoBairro(event.target.value)}
                        value={uEndereco_bairro}
                    />

                    <TextInput placeholder='Cidade'
                        onChange={event => setUEnderecoCidade(event.target.value)}
                        value={uEndereco_cidade}
                        disabled
                    />

                    <TextInput placeholder='Estado'
                        onChange={event => setUEnderecoEstado(event.target.value)}
                        value={uEndereco_estado}
                        disabled
                    />
                </Bx >
                {loadingUseEffect ? (<>
                    <div className="middle">
                        <div className="bar bar1"></div>
                        <div className="bar bar2"></div>
                        <div className="bar bar3"></div>
                        <div className="bar bar4"></div>
                        <div className="bar bar5"></div>
                        <div className="bar bar6"></div>
                        <div className="bar bar7"></div>
                        <div className="bar bar8"></div>
                    </div> </>) : (<>




                        <Bx style={size !== 'small' ? style.form : style.formSmall} gap='medium'>

                            {/* <<<<<<< HEAD */}
                            <Text style={{ fontFamily: 'Poppins', fontSize: 30, color: '#f5500c' }}>Dados do produto</Text>
                            {/* =======
                            <Text style={{ fontFamily: 'Syne-Bold', fontSize: 30, color: '#f5500c' }}>Dados do produto</Text>
>>>>>>> 4e83f6896a77960ac82a9ecb2c31e9a0f73d10c4 */}


                            <Select placeholder="Produto" style={{ color: 'black' }} options={['Moeda', 'Medalha']} onChange={({ option }) => setUProduto(option)} />


                            <TextInput placeholder='Quantidade - minimo 50' type='number' onChange={(event) => setUQuantidade(event.target.value)} value={uQuantidade} onBlur={handleBlurQty} />

                            <Select placeholder="Você já tem a arte do projeto?" options={['Sim, tenho a arte', 'Não, não tenho a arte']} onChange={({ option }) => setUArte(option)} />

                            {
                                clenaing ? (<></>) : (

                                    <FileInput messages={{
                                        browse: "Anexar",
                                        dropPrompt: "Se tiver a arte, envie-nos o seu projeto - frente",
                                        files: "files",
                                        remove: "remover",
                                    }}
                                        accept="image/png, image/gif, image/jpeg"
                                        id='fotos'
                                        name="foto-obrigatoria"

                                        onChange={(event, { files }) => {
                                            const fileList = files;
                                            for (let i = 0; i < fileList.length; i += 1) {
                                                const file = fileList[i];
                                                console.log(file);
                                                setFoto1(file);


                                            }
                                        }}
                                    />


                                )
                            }

                            {
                                clenaing ? (<></>) : (


                                    <FileInput messages={{
                                        browse: "Anexar",
                                        dropPrompt: "Se tiver a arte, envie-nos o seu projeto - verso",
                                        files: "files",
                                        remove: "remover",
                                    }}
                                        accept="image/png, image/gif, image/jpeg"
                                        id='fotos'
                                        name="foto-obrigatoria"

                                        onChange={(event, { files }) => {
                                            const fileList = files;
                                            for (let i = 0; i < fileList.length; i += 1) {
                                                const file = fileList[i];
                                                console.log(file);
                                                setFoto2(file);
                                            }
                                        }}
                                    />

                                )
                            }
                            <Box>
                                <DateInput format="dd/mm/yyyy" placeholder='Para quando você precisa? ' onChange={({ value }) => { verificarData(value) }} value={uDate} />
                            </Box>
                            <Select placeholder="Diâmetro - em cm (centímetros)"
                                options={useEffectDados.diametro}
                                labelKey="medida"
                                valueKey={{ key: 'medida', reduce: true }}
                                onChange={({ value: nextValue }) => setUTamanho(nextValue)}
                                value={uTamanho} />

                            <Select placeholder="Espessura - em mm (milímetros)"
                                options={useEffectDados.espessura}
                                labelKey="medida"
                                valueKey={{ key: 'medida', reduce: true }}
                                onChange={({ value: nextValue }) => setULargura(nextValue)}
                                value={uLargura} />

                            <Select placeholder="Material" options={['Bronze', 'Zinco']} onChange={({ option }) => setUMaterial(option)} value={uMaterial} />

                            <Select
                                placeholder="Esmalte"
                                options={useEffectDados.esmalte}
                                labelKey="nome"
                                valueKey={{ key: 'nome', reduce: true }}
                                onChange={({ value: nextValue }) => {
                                    if (nextValue === 'Esmalte Duplo') {
                                        setSelectEsmalte(true);
                                    }
                                    else {
                                        setSelectEsmalte(false);
                                        setUEsmalte(nextValue);
                                    }

                                }}
                                value={uEsmalte}
                            />

                            {
                                selectEsmalte === true ?
                                    <Select
                                        placeholder="Esmalte"
                                        options={useEffectDados.esmalteDuplo}
                                        labelKey="nome"
                                        valueKey={{ key: 'nome', reduce: true }}
                                        onChange={({ value: nextValue }) => {
                                            setUEsmalte(nextValue);
                                        }}
                                    // value={uEsmalte}
                                    /> : ""
                            }
                            <Select
                                placeholder="Cor"
                                options={useEffectDados.cor}
                                labelKey="nome"
                                valueKey={{ key: 'nome', reduce: true }}
                                onChange={({ value: nextValue }) => setUCor(nextValue)}
                                value={uCor} />

                            <Select placeholder="3D"
                                options={useEffectDados.m3d}
                                labelKey="nome"
                                valueKey={{ key: 'nome', reduce: true }}
                                onChange={({ value: nextValue }) => setU3d(nextValue)}
                                value={u3d} />

                            <Select placeholder="Borda"
                                options={useEffectDados.borda}
                                labelKey="nome"
                                valueKey={{ key: 'nome', reduce: true }}
                                onChange={({ value: nextValue }) => {
                                    if (nextValue === 'Borda especial') {
                                        setSelectBorda(true);
                                    }
                                    else {
                                        setSelectBorda(false);
                                    }
                                    setUBorda(nextValue);
                                }
                                }
                                value={uBorda} />

                            {
                                selectBorda ?
                                    <Select
                                        options={['Borda 1', 'Borda 2']}
                                        placeholder="Escolher borda"
                                    //   value={value}
                                    //   onChange={({ option }) => setValue(option)}
                                    /> : ""
                            }
                            <Select placeholder="Gravado"
                                options={useEffectDados.gravado}
                                labelKey="nome"
                                valueKey={{ key: 'nome', reduce: true }}
                                onChange={({ value: nextValue }) => setUGravado(nextValue)}
                                value={uGravado} />

                            <Select placeholder="Embalagem"
                                options={useEffectDados.embalagem}
                                labelKey="nome"
                                valueKey={{ key: 'nome', reduce: true }}
                                onChange={({ value: nextValue }) => setUEmbalagem(nextValue)}
                                value={uEmbalagem} />

                            <Box>
                                <TextArea
                                    placeholder="Observações"
                                    value={obs}
                                    onChange={event => setObs(event.target.value)}
                                // size={'medium'}
                                // style={{ height: '200px', width: '100%' }}
                                />
                            </Box>

                            {/* <<<<<<< HEAD */}
                            <Btn primary label="Solicitar orçamento" className='' style={{ backgroundColor: '#ff5700', border: 'none', marginBottom: '10%', borderRadius: '0px' }} onClick={() => {
                                // =======
                                //                             <Btn primary label="Solicitar orçamento" style={{ backgroundColor: '#ff5700', border: 'none', marginBottom: '10%', borderRadius: '0px' }} onClick={() => {
                                // >>>>>>> 4e83f6896a77960ac82a9ecb2c31e9a0f73d10c4
                                OpenModalOption();
                            }} color='black' />

                        </Bx >

                    </>)}

            </Stack >


            <FooterBody />



        </Main >
    );
}

export default Orc;
