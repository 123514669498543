import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './fonts/pricedown/pricedown-bl.ttf';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import {
  Routes,
  Route,
} from "react-router-dom";
import { Grommet } from 'grommet';

import Landing from './screens/landing/landing'
import Orc from './screens/orcamento/orc';
import Login from './screens/login/login';
import LoginScreen from './screens/login/loginScreen';
import AdminAjustes from './screens/login/adminajustes';
import StatusPedido from './screens/status/statuspedido';

import SelectIcon from './assets/selectIcon.png';

const theme = {
  global: {
    font: {
      family: 'Syne-Bold',
      size: '14px',
      height: '20px',
    },
    button: {
      font: { family: 'Syne-Bold' },
    },
    focus: {
      border: {
        color: '#ffa577'
      }
    }
  },
  select: {
    icons: {
      // down: <SelectIcon />,
      color: 'dark-1',
      margin: 'small',
    },
  },
  fileInput: {
    button: {
    },
  },
};


ReactDOM.render(
  <Grommet theme={theme}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Orc />} />
        <Route path="/orcamento" element={<Orc />} />
        <Route path="/orcamento/admin/login" element={<LoginScreen />} />
        <Route path="/orcamento/admin" element={<Login />} />
        <Route path="/orcamento/admin/ajustes" element={<AdminAjustes />} />
        <Route path="/orcamento/status/:id" element={<StatusPedido />} />

        {/* <Route path="/" element={<Landing />} />
        <Route path="/orcamento" element={<Orc />} />
        <Route path="/chilecoins" element={<HistoriaChilecoins />} />
        <Route path="/servicos" element={<ServicosScreen />} />
        <Route path="/portifolio" element={<PortifolioScreen />} /> */}


        {/* <Route path="/admin" element={<Login />} />
        <Route path="/admin/ajustes" element={<AdminAjustes />} /> */}

        <Route path="/status/:id" element={<StatusPedido />} />
      </Routes>
    </BrowserRouter>
  </Grommet>,
  document.getElementById('root')
);
