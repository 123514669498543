import React from 'react';
import logo from '../../assets/logo.png'
import { Header, Box as Bx, Text, TextInput, Button as Btn, Card, DataTable, Select, Button, Table, TableHeader, TableBody, TableRow, TableCell, ResponsiveContext, Tab, Tabs, FileInput, TextArea, Main } from 'grommet';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Bt from '@mui/material/Button';
import api from '../../services/api'
import { red } from '@mui/material/colors';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import moment from 'moment';

function TableList({ data, teste, setDatum, handleSelectOrcamento, update, setUpdate }) {

    const HorasPassadas = ({ dados }) => {

        const now = new Date(); // Data de hoje
        const past = new Date(dados.horas); // Outra data no passado
        const diff = Math.abs(now.getTime() - past.getTime()); // Subtrai uma data pela outra
        const minutos = Math.ceil(diff / (1000 * 60)); // Divide o total pelo total de milisegundos correspondentes a 1 dia. (1000 milisegundos = 1 segundo).

        const horas = Math.floor(minutos / 60);
        const min = minutos % 60;
        const textoHoras = (`00${horas}`).slice(-2);
        const textoMinutos = (`00${min}`).slice(-2);

        return (
            <Text style={{ whiteSpace: 'nowrap' }}>{textoHoras} horas e {textoMinutos} minutos atras </Text>
        )
    }

    const exclude = (id) => {
        api.post('/excluir/' + id).then((data) => {
            setDatum({ ...data, pendentes: data.pendentes.filter((item) => item.id !== id) });
            Swal.fire(
                'Sucesso!',
                'Pedido excluido',
                'success'
            )
        })
    }
    const TableInfo = ({ dataProps, prox, end }) => {
        return <DataTable
            // size="100vw"
            columns={[
                {
                    property: 'id_cotacao',
                    header: <Text style={{ whiteSpace: 'nowrap' }}>N° Cotacao</Text>,
                    primary: true,
                },
                {
                    property: 'data',
                    header: <Text style={{ whiteSpace: 'nowrap' }}>Data</Text>,
                    primary: true,
                },
                {
                    property: 'data',
                    header: <Text style={{ whiteSpace: 'nowrap' }}>Mandando a</Text>,
                    render: datum => (
                        <HorasPassadas dados={datum} />
                    ),
                },
                {
                    property: 'nome',
                    header: <Text style={{ whiteSpace: 'nowrap' }}>Nome</Text>,
                    primary: true,
                },
                {
                    property: 'sobrenome',
                    header: <Text style={{ whiteSpace: 'nowrap' }}>Sobrenome</Text>,
                    primary: true,
                },
                {
                    property: 'telefone',
                    header: <Text style={{ whiteSpace: 'nowrap' }}>Telefone</Text>,
                    primary: true,
                },
                {
                    property: 'email',
                    header: <Text style={{ whiteSpace: 'nowrap' }}>Email</Text>,
                    primary: true,
                },
                {
                    property: 'percent',
                    header: <Text style={{ whiteSpace: 'nowrap' }}>Opcoes</Text>,
                    render: datum => (
                        <Bx pad={{ vertical: 'xsmall' }}>
                            {prox
                                ? <Button label={<Text color={"white"}>Enviar para o próximo estágio</Text>} style={{ backgroundColor: '#00ed1b', border: 'none' }} onClick={() => proximoEstagio(datum)} />
                                : end
                                    ? <></>
                                    :
                                    <Box gap="small">
                                        <Button label={<Text color={"white"}>Fazer orçamento </Text>} style={{ backgroundColor: '#00ed1b', border: 'none' }} onClick={() => {
                                            handleSelectOrcamento(datum)
                                        }} />
                                        <Button label={<Text color={"white"}> Excluir </Text>} style={{ backgroundColor: 'red', border: 'none' }} onClick={() => exclude(datum.id)} />
                                    </Box>
                            }
                        </Bx>
                    ),
                },
            ]}

            data={dataProps}
        />
    }

    const proximoEstagio = (data) => {

        Swal.fire({
            title: 'Tem certeza que deseja mandar o pedido para a proxima etapa??',
            // text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Não!',
            confirmButtonText: 'Sim!'
        }).then((result) => {
            if (result.isConfirmed) {
                api.get('novo-estagio-orcamento/' + data.id).then(res => {
                    setUpdate(!update);
                    Swal.fire(
                        'Sucesso!',
                        'Estamos na próxima fase',
                        'success'
                    )
                })

            }
        })

    }

    return (
        <Main width={"100vw"} style={{ overflow: 'scroll' }}>
            {data && <Tabs>
                <Tab title="Pendentes">
                    <TableInfo dataProps={data.pendentes} key="pendentes" />
                </Tab>
                <Tab title="Enviados">
                    <TableInfo dataProps={data.enviados} prox={true} />
                </Tab>
                <Tab title="Aceitos">
                    <TableInfo dataProps={data.aceitos} prox={true} />
                </Tab>
                <Tab title="Em produção">
                    <TableInfo dataProps={data.producao} prox={true} />
                </Tab>
                <Tab title="A caminho">
                    <TableInfo dataProps={data.caminho} prox={true} />
                </Tab>
                <Tab title="Concluido">
                    <TableInfo dataProps={data.concluido} end={true} />
                </Tab>
                <Tab title="Perdido">
                    <TableInfo dataProps={data.perdido} end={true} />
                </Tab>
            </Tabs>}
        </Main>
    );
}

export default TableList;