
import logo from '../../assets/logo.png'
import React from 'react';
import { Header, Box as Bx, Text, TextInput, Button as Btn, Card, DataTable, Select, Button, Table, TableHeader, TableBody, TableRow, TableCell, ResponsiveContext, Tab, Tabs, FileInput } from 'grommet';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Bt from '@mui/material/Button';
import api from '../../services/api'
import { red } from '@mui/material/colors';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link, useParams } from "react-router-dom";

import './status.css';

const StatusPedido = () => {
    const { id } = useParams();

    const style = {
        navbarAtual: { backgroundColor: 'rgb(243 194 102)', '&:hover': { backgroundColor: 'rgb(243 194 102)', opacity: [0.9, 0.8, 0.7], }, height: '5vh', fontFamily: 'Poppins', textDecoration: 'none', },
        navbarSecundario: { backgroundColor: 'transparent', '&:hover': { backgroundColor: 'rgb(243 194 102)', opacity: [0.9, 0.8, 0.7], }, height: '5vh', fontFamily: 'Poppins' },
        card: {
            left: '30%',
            right: '30%',
            top: 'xlarge'
        },
        cardSmall: {
            left: '2%',
            right: '2%',
            top: 'xlarge'
        },
        table: { "borderCollapse": "collapse", "width": "100%" },
        th: { "border": "1px solid #dddddd", "textAlign": "left", "padding": "8px", "fontSize": "13px", backgroundColor: '#dddddd' },

    }

    const [dados, setDados] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    React.useEffect(() => {

        api.get('get-orcamento-unico/' + id).then(res => {
            setDados(res.data.dados);
            console.log(res.data.dados);
            setLoading(false);
        })
    }, [])

    return (
        <>
            {loading ? <></> : <>
                <Header pad={{ left: 'xlarge', right: 'xlarge' }} background={{ color: 'rgb(179 179 179)' }}>
                    <img src={logo} style={{ height: '10vh', alignSelf: 'center' }} />


                </Header>

                <Bx style={{ width: '70%', marginLeft: '14%', marginTop: '1%' }}>
                    <div>
                        <article className="card">
                            <header className="card-header"> Meu pedido</header>
                            <div className="card-body">
                                <h6>Orçamento N°: {dados.id_cotacao}</h6>
                                <article className="card">
                                    <div className="card-body row">
                                        <div className="col"> <strong>Data de entrega para o pedido:</strong> <br /> {dados.dataEntrega} </div>
                                        <div className="col"> <strong>Destinatario:</strong> <br /> {dados.nome} {dados.sobrenome} </div>
                                        <div className="col"> <strong>Status:</strong> <br />
                                            {
                                                dados.status === 2 ? "Pedido Recebido"
                                                    : dados.status === 3 ? "Pedido em produção"
                                                        : dados.status === 4 ? "Pedido a caminho" :
                                                            dados.status === 5 ? "Pedido Concluído" : "Pedido indisponivel"} </div>
                                        {/* <div className="col"> <strong>Tracking #:</strong> <br /> BD045903594059 </div> */}
                                    </div>
                                </article>
                                <div className="track">
                                    <div className={dados.status >= 2 ? "step active" : "step"}> <span className="icon"> <i className="fa fa-check" /> </span> <span className="text">Pedido Confirmado</span> </div>
                                    <div className={dados.status >= 3 ? "step active" : "step"}> <span className="icon"> <i className="fa fa-user" /> </span> <span className="text"> Em produção</span> </div>
                                    <div className={dados.status >= 4 ? "step active" : "step"}> <span className="icon"> <i className="fa fa-truck" /> </span> <span className="text"> A caminho </span> </div>
                                    <div className={dados.status >= 5 ? "step active" : "step"}> <span className="icon"> <i className="fa fa-box" /> </span> <span className="text">Concluido</span> </div>
                                </div>
                                {/* <hr />
                            <ul className="row">
                                <li className="col-md-4">
                                    <figure className="itemside mb-3">
                                        <div className="aside"><img src="https://i.imgur.com/iDwDQ4o.png" className="img-sm border" /></div>
                                        <figcaption className="info align-self-center">
                                            <p className="title">Dell Laptop with 500GB HDD <br /> 8GB RAM</p> <span className="text-muted">$950 </span>
                                        </figcaption>
                                    </figure>
                                </li>
                                <li className="col-md-4">
                                    <figure className="itemside mb-3">
                                        <div className="aside"><img src="https://i.imgur.com/tVBy5Q0.png" className="img-sm border" /></div>
                                        <figcaption className="info align-self-center">
                                            <p className="title">HP Laptop with 500GB HDD <br /> 8GB RAM</p> <span className="text-muted">$850 </span>
                                        </figcaption>
                                    </figure>
                                </li>
                                <li className="col-md-4">
                                    <figure className="itemside mb-3">
                                        <div className="aside"><img src="https://i.imgur.com/Bd56jKH.png" className="img-sm border" /></div>
                                        <figcaption className="info align-self-center">
                                            <p className="title">ACER Laptop with 500GB HDD <br /> 8GB RAM</p> <span className="text-muted">$650 </span>
                                        </figcaption>
                                    </figure>
                                </li>
                            </ul>
                            <hr />
                            <a href="#" className="btn btn-warning" data-abc="true"> <i className="fa fa-chevron-left" /> Back to orders</a> */}
                            </div>
                        </article>
                    </div>
                </Bx ></>}



        </>
    );

}

export default StatusPedido;
