

import React from 'react';
import { Box as Bx, Text, Tab, Tabs, FileInput, Box, Button, Image } from 'grommet';
import Stack from '@mui/material/Stack';
import ImageUploading from 'react-images-uploading';
import api from '../../services/api';

export const ModalDeFotos = ({ dadosTabelaOrcamento }) => {
    const [images, setImages] = React.useState([]);
    const [imagesVerso, setImagesVerso] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const onChangeImage = (imageList, addUpdateIndex) => {
        console.log(imageList[0].file, addUpdateIndex);
        setImages(imageList);
    };

    const onChangeImageVerso = (imageList, addUpdateIndex) => {
        console.log(imageList[0].file, addUpdateIndex);
        setImagesVerso(imageList);
    };

    const handleSubmit = (pos) => {
        setLoading(true);
        const formData = new FormData();
        if (pos === 'frente')
            formData.append("image", images[0].file);
        else
            formData.append("image", imagesVerso[0].file);

        api.post(`/save-image/${pos}/${dadosTabelaOrcamento[0].id}`, formData, {
            headers: {
                accept: "application/json",
                "Accept-Language": "en-US,en;q=0.8",
                "Content-Type": `multipart/form-data;`,
            },
        })
            .then(({ data }) => {
                if (pos === `frente`)
                    setImages([])
                else
                    setImagesVerso([]);
                setOrc(data);
                setLoading(false);
            });
    };

    const [orc, setOrc] = React.useState([]);

    React.useEffect(() => {
        api.get(`/get/fotos/${dadosTabelaOrcamento[0].id}`).then(({ data }) => {
            setOrc(data);
        })
    }, [])
    return (
        <Tabs>
            {dadosTabelaOrcamento.map((dados, key) =>
            (<>
                <Tab title={"Moeda " + (key + 1)}>



                    <Text> Frente:</Text>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={4}
                    >
                        {loading ? <></> : dados.foto_frente
                            ? <img src={'https://chilecoins.com.br/app/public/fotos_orcamento/' + orc.foto_frente + '?timestamp=' + new Date().getTime()} style={{ width: '200px' }} />
                            : <img src={'https://www.goiania.go.leg.br/imagens/sem-foto/image'} style={{ width: '200px' }} />}

                        <Bx>
                            <ImageUploading
                                value={images}
                                onChange={onChangeImage}
                                dataURLKey="data_url"
                                acceptType={["jpg"]}
                            >
                                {({
                                    imageList,
                                    onImageUpload,
                                    onImageRemoveAll,
                                    onImageUpdate,
                                    onImageRemove,
                                    isDragging,
                                    dragProps,
                                }) => (
                                    <Box
                                        style={{ border: "1px solid black" }}
                                        align="center"
                                        justify="center"
                                        pad="small"
                                    >
                                        <Button
                                            primary
                                            label={<Text color={"white"}>Selecionar imagem</Text>}
                                            color={"#22b877"}
                                            onClick={onImageUpload}
                                            {...dragProps}
                                        />
                                        &nbsp;
                                        {imageList.map((image, index) => (
                                            <div key={index} className="image-item">
                                                <Image
                                                    src={image.data_url}
                                                    alt=""
                                                    width={"100%"}
                                                    style={{ maxWidth: "35vw", maxHeight: "25vh" }}
                                                    fit="contain"
                                                />
                                                <Box gap='small'>
                                                    <Button
                                                        primary
                                                        label={<Text color={"white"}>Atualizar Imagem</Text>}
                                                        color={"#22b877"}
                                                        onClick={() => handleSubmit('frente')}
                                                    />

                                                    <Button
                                                        primary
                                                        label={<Text color={"white"}>Cancelar</Text>}
                                                        color={"red"}
                                                        onClick={() => setImages([])}
                                                        {...dragProps}
                                                    />
                                                </Box>
                                            </div>
                                        ))}
                                    </Box>
                                )}
                            </ImageUploading>
                        </Bx>
                    </Stack>

                    <Text> Verso:</Text>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={4}
                    >
                        {loading
                            ? <></>
                            : dados.foto_verso
                                ? <img src={'https://chilecoins.com.br/app/public/fotos_orcamento/' + orc.foto_verso + '?timestamp=' + new Date().getTime()} style={{ width: '200px' }} />
                                : <img src={'https://www.goiania.go.leg.br/imagens/sem-foto/image'} style={{ width: '200px' }} />
                        }

                        <Bx>
                            <ImageUploading
                                value={imagesVerso}
                                onChange={onChangeImageVerso}
                                dataURLKey="data_url"
                                acceptType={["jpg"]}
                            >
                                {({
                                    imageList,
                                    onImageUpload,
                                    onImageRemoveAll,
                                    onImageUpdate,
                                    onImageRemove,
                                    isDragging,
                                    dragProps,
                                }) => (
                                    <Box
                                        style={{ border: "1px solid black" }}
                                        align="center"
                                        justify="center"
                                        pad="small"
                                    >
                                        <Button
                                            primary
                                            label={<Text color={"white"}>Selecionar imagem</Text>}
                                            color={"#22b877"}
                                            onClick={onImageUpload}
                                            {...dragProps}
                                        />
                                        &nbsp;
                                        {imageList.map((image, index) => (
                                            <div key={index} className="image-item">
                                                <Image
                                                    src={image.data_url}
                                                    alt=""
                                                    width={"100%"}
                                                    style={{ maxWidth: "35vw", maxHeight: "25vh" }}
                                                    fit="contain"
                                                />
                                                <Box gap='small'>
                                                    <Button
                                                        primary
                                                        label={<Text color={"white"}>Atualizar Imagem</Text>}
                                                        color={"#22b877"}
                                                        onClick={() => handleSubmit('verso')}
                                                    />

                                                    <Button
                                                        primary
                                                        label={<Text color={"white"}>Cancelar</Text>}
                                                        color={"red"}
                                                        onClick={() => setImagesVerso([])}
                                                        {...dragProps}
                                                    />
                                                </Box>
                                            </div>
                                        ))}
                                    </Box>
                                )}
                            </ImageUploading>
                        </Bx>
                    </Stack>
                </Tab>
            </>)

            )}
        </Tabs >
    )
}

export default ModalDeFotos;