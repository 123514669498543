
import logo from '../../assets/logo.png'
import React from 'react';
import { Header, Box as Bx, Text, TextInput, Button as Btn, Card, DataTable, Select, Button, Table, TableHeader, TableBody, TableRow, TableCell, ResponsiveContext, Tab, Tabs, FileInput, Spinner } from 'grommet';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Bt from '@mui/material/Button';
import api from '../../services/api'
import { red } from '@mui/material/colors';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'

const AdminAjustes = () => {

    const [logado, setLogado] = React.useState(false);
    const [user, setUser] = React.useState([]);
    const navigate = useNavigate();



    const style = {
        navbarAtual: { backgroundColor: 'rgb(243 194 102)', '&:hover': { backgroundColor: 'rgb(243 194 102)', opacity: [0.9, 0.8, 0.7], }, height: '5vh', fontFamily: 'Poppins' },
        navbarSecundario: { backgroundColor: 'transparent', '&:hover': { backgroundColor: 'rgb(243 194 102)', opacity: [0.9, 0.8, 0.7], }, height: '5vh', fontFamily: 'Poppins' },
        card: {
            left: '30%',
            right: '30%',
            top: 'xlarge'
        },
        cardSmall: {
            left: '2%',
            right: '2%',
            top: 'xlarge'
        },
        table: { "borderCollapse": "collapse", "width": "100%" },
        th: { "border": "1px solid #dddddd", "textAlign": "left", "padding": "8px", "fontSize": "13px", backgroundColor: '#dddddd' },

        // cardsServices: { }
    }


    React.useEffect(() => {

        const token = localStorage.getItem('token');
        if (token) {
            api.defaults.headers.Authorization = `Bearer ${JSON.parse(token)}`;

            api.post('/me').then((res) => {
                if (res.data.message === 'sucess') {
                    setUser(res.data.usuario.original);
                    setLogado(true);
                }
                else {
                    localStorage.clear();
                    navigate('/');
                }
            })
        }
        else {
            console.log('erro');
            navigate('/');
        }
    }, [])




    const size = React.useContext(ResponsiveContext);

    const [opcoesDeProduto, setOpcPrd] = React.useState([]);

    const AtualizarSelectValores = (produto) => {
        setNomeProdutoVlr(produto);
        console.log(produto);
        setLoadSelect(true);
        api.get('getProdutos-By-Name/' + produto).then(res => {
            console.log(res.data);
            setOpcPrd(res.data);
            setLoadSelect(false);
        })
    }

    const [valorProduto, setVlrProduto] = React.useState("");
    const [valorProdutoAtual, setVlrProdutoAtual] = React.useState("");
    const [loadSelect, setLoadSelect] = React.useState(false);
    const [idProdutoAtualizarValor, setIdProdutoVlr] = React.useState("");
    const [nomeProdutoAtualizarValor, setNomeProdutoVlr] = React.useState("");

    const [novoProdutoSelect, setNovoProdutoSelect] = React.useState("");
    const [novoProdutoNome, setNovoProdutoNome] = React.useState("");
    const [novoProdutoValor, setNovoProdutoValor] = React.useState("");
    // const TabValoresProduto = () => {
    //     return (

    //     );
    // }

    const apiatualizarvalor = () => {

        let data = {
            id: idProdutoAtualizarValor,
            nome: nomeProdutoAtualizarValor,
            valor: valorProduto
        };
        api.post('atualizar-valor-produtos', data).then(res => {
            setLoadSelect(true);
            Swal.fire({
                icon: 'success',
                title: 'Sucesso',
                text: 'Valores Atualizados',
            }).then(() => setLoadSelect(false))
            setVlrProduto("");
            setNomeProdutoVlr("");
        })
    }

    const apicriarproduto = () => {
        console.log(novoProdutoSelect);
        let data = {
            nome: novoProdutoSelect,
            produto: novoProdutoNome,
            valor: novoProdutoValor
        };
        api.post('criar-novo-produto', data).then(res => {
            console.log(res.data);
            Swal.fire({
                icon: 'success',
                title: 'Sucesso',
                text: 'Produto criado',
            })
            setNovoProdutoNome("");
            setNovoProdutoValor("");
        })
    }
    if (logado) {
        return (
            <>
                <Header pad={{ left: 'xlarge', right: 'xlarge' }} background={{ color: 'rgb(179 179 179)' }}>
                    <img src={logo} style={{ height: '10vh', alignSelf: 'center' }} />

                    <Stack direction="row" mt={2} spacing={2} sx={{ position: 'relative' }}>

                        <Link to="/orcamento/admin" style={{ textDecoration: 'none' }}>
                            <Bt variant="contained" sx={style.navbarAtual} >Orcamentos</Bt>
                        </Link>
                        <Bt variant="contained" sx={style.navbarAtual} onClick={() => { localStorage.clear(); navigate('/orcamento/admin'); }} >Sair</Bt>

                    </Stack>
                </Header>

                <Tabs>
                    <Tab title="Valores">
                        <Bx align="center" gap='medium'>
                            <Select
                                options={['Material', 'Esmalte', 'Cor', '3D', 'Borda', 'Gravado', 'Embalagem']}
                                size='medium'
                                placeholder='Selecionar Produto'
                                value={nomeProdutoAtualizarValor}
                                onChange={({ option }) => AtualizarSelectValores(option)}
                            />
                            {loadSelect ? (<><Spinner /></>) : <Select
                                options={opcoesDeProduto}
                                labelKey="nome"
                                valueKey={{ key: 'id', reduce: true }}
                                onChange={(option) => { console.log(option.option); setVlrProduto(option.option.valor_total); setVlrProdutoAtual(option.option.valor_total); setIdProdutoVlr(option.option.id) }}
                                size='medium'
                            />}

                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                            >

                                <TextInput
                                    placeholder="Valor"
                                    id="valorProdutoInput"
                                    style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}
                                    value={valorProduto}
                                    onChange={event => setVlrProduto(event.target.value)}
                                    type="number"
                                    size='medium'
                                />
                            </Stack>

                            <Text> Valor atual: R$ {valorProdutoAtual}</Text>

                            <Bt variant="contained" sx={style.navbarAtual} onClick={() => { apiatualizarvalor() }} >Atualizar valor</Bt>
                        </Bx >
                    </Tab>

                    <Tab title="Produtos">
                        <Bx align="center" gap='medium'>
                            <Select
                                options={['Material', 'Esmalte', 'Cor', '3D', 'Borda', 'Gravado', 'Embalagem']}
                                size='medium'
                                placeholder='Selecionar Produto'
                                onChange={({ option }) => setNovoProdutoSelect(option)}
                            />

                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                            >

                                <TextInput
                                    placeholder="Nome"
                                    id="valorProdutoInput"
                                    style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}
                                    value={novoProdutoNome}
                                    onChange={event => setNovoProdutoNome(event.target.value)}
                                    size='medium'
                                />
                            </Stack>

                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                            >

                                <TextInput
                                    placeholder="Valor"
                                    id="valorProdutoInput"
                                    style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}
                                    value={novoProdutoValor}
                                    onChange={event => setNovoProdutoValor(event.target.value)}
                                    type="number"
                                    size='medium'
                                />
                            </Stack>

                            <Bt variant="contained" sx={style.navbarAtual} onClick={() => { apicriarproduto() }} >Criar novo produto</Bt>
                        </Bx >
                    </Tab>
                </Tabs>





            </>
        );
    }
    else {
        return <></>
    }
}

export default AdminAjustes;
