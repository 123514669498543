
import logo from '../../assets/logo.png'
import React from 'react';
import { Header, Box as Bx, Text, TextInput, Button as Btn, Card, DataTable, Select, Button, Table, TableHeader, TableBody, TableRow, TableCell, ResponsiveContext, Tab, Tabs, FileInput, TextArea, Main } from 'grommet';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Bt from '@mui/material/Button';
import api from '../../services/api'
import { red } from '@mui/material/colors';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import moment from 'moment';

import TableList from '../../Components/admin/TableList';
import InformacoesDoCliente from '../../Components/admin/InformacoesDoCliente';
import ModalDeFotos from '../../Components/admin/ModalDeFotos';
const Login = () => {
    const navigate = useNavigate();

    const [tableDatum, setDatum] = React.useState(null);
    const [update, setUpdate] = React.useState(false);

    const [logado, setLogado] = React.useState(false);
    const [orcando, setOrcando] = React.useState(false);
    const [user, setUser] = React.useState([]);
    const [DadosGlobal, setDG] = React.useState([]);
    const [dataOrc, setDataOrc] = React.useState(null);
    const [unome, setUnome] = React.useState('');
    const [usobrenome, setUsobrenome] = React.useState('');
    const [utelefone, setUtelefone] = React.useState('');
    const [uemail, setUemail] = React.useState('');
    const [uano, setUano] = React.useState('');
    const [uObs, setObs] = React.useState('');
    const [total, setTotal] = React.useState(0);
    const [dadosTable, setDadosTable] = React.useState([]);
    const [dadosTableEnviados, setDadosTableEnviados] = React.useState([]);
    const [dadosTableAceitos, setDadosTableAceitos] = React.useState([]);
    const [dadosTableProducao, setDadosTableProducao] = React.useState([]);
    const [dadosTableCaminho, setDadosTableCaminho] = React.useState([]);
    const [dadosTableConcluido, setDadosTableConcluido] = React.useState([]);
    const [dadosTablePerdido, setDadosTablePerdido] = React.useState([]);
    const [idOrc, setIdorc] = React.useState(null);
    const [idCotacao, setIdCotacao] = React.useState(null);
    const size = React.useContext(ResponsiveContext);
    const [sidebar, setSidebar] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [openImages, setModalImages] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [loadingValores, setLoading] = React.useState(true);
    const [valorCor, setVlrCor] = React.useState(true);
    const [desconto, setDesconto] = React.useState('');
    const [openProdutos, setOpenProdutos] = React.useState(false);
    const [valorProdutoUnitario, setVlrProdutoUnitario] = React.useState([]);
    const [testeproduto, setTesteProduto] = React.useState('Material');
    const [openModalValores, setModalValores] = React.useState(false);
    const [novoNome, setNovoNome] = React.useState('');
    const [novoEmail, setNovoEmail] = React.useState('');
    const [novoSenha, setNovoSenha] = React.useState('');
    const [dadosTabelaOrcamento, setDtb] = React.useState([]);

    React.useEffect(() => {

        const token = localStorage.getItem('token');
        if (token) {
            api.defaults.headers.Authorization = `Bearer ${JSON.parse(token)}`;

            api.post('/me').then((res) => {
                if (res.data.message === 'sucess') {
                    setUser(res.data.usuario.original);
                    api.get('listar-informacoes-orcamentos')
                        .then(res => {
                            console.log(res.data);

                            const PendetesFiltrados = res.data.pendetes
                                .sort(({ seq: a }, { seq: b }) => b - a)
                                .filter(({ id_cotacao }, i) => i === res.data.pendetes.findIndex(({ id_cotacao: _id }) => id_cotacao === _id));

                            setDatum({ ...res.data, pendentes: PendetesFiltrados });


                            setDadosTable(PendetesFiltrados);
                            setDadosTableEnviados(res.data.enviados);
                            setDadosTableAceitos(res.data.aceitos);
                            setDadosTableProducao(res.data.producao);
                            setDadosTableCaminho(res.data.caminho);
                            setDadosTableConcluido(res.data.concluido);
                            setDadosTablePerdido(res.data.perdido);
                            setLogado(true);
                        })
                }
                else {
                    navigate('/orcamento/admin/login')
                }
            })
                .catch((error) => navigate('/orcamento/admin/login'));
        }
        else {
            navigate('/orcamento/admin/login')
        }
    }, [orcando, update]);



    const handleSendOrcament = () => {
        var datatime = new Date();
        var dia = String(datatime.getDate()).padStart(2, '0');
        var mes = String(datatime.getMonth() + 1).padStart(2, '0');
        var ano = String(datatime.getFullYear());
        var fecha = dia + '/' + mes + '/' + ano;

        let data = {
            telefone: utelefone,
            obs: uObs,
            nome: unome,
            sobrenome: usobrenome,
            email: uemail,
            ano: uano,
            valor_total: total,
            data: fecha,
            id_funcionario: user.id,
            nome_funcionario: user.nome,
            idOrc: idOrc,
            cotacao: idCotacao,
            desconto: desconto,
        }

        console.clear();
        console.log(data);
        api.post('/enviar-orcamento', data).then((res) => {
            alert('Orcamento enviado');
            window.open(`https://chilecoins.com.br/app/api/pdf/${res.data}`, '_blank');
            setOrcando(false);
            // clearDados();
        }).catch(error => {
            alert('Erro ao enviar orçamento')
        })
    }

    const style = {
        navbarAtual: { backgroundColor: 'rgb(243 194 102)', '&:hover': { backgroundColor: 'rgb(243 194 102)', opacity: [0.9, 0.8, 0.7], }, height: '5vh', fontFamily: 'Poppins', textDecoration: 'none', },
        navbarSecundario: { backgroundColor: 'transparent', '&:hover': { backgroundColor: 'rgb(243 194 102)', opacity: [0.9, 0.8, 0.7], }, height: '5vh', fontFamily: 'Poppins' },
        card: {
            left: '30%',
            right: '30%',
            top: 'xlarge'
        },
        cardSmall: {
            left: '2%',
            right: '2%',
            top: 'xlarge'
        },
        table: { "borderCollapse": "collapse", "width": "100%" },
        th: { "border": "1px solid #dddddd", "textAlign": "left", "padding": "8px", "fontSize": "13px", backgroundColor: '#dddddd', whiteSpace: 'nowrap' },
        thW: { "border": "1px solid #dddddd", "textAlign": "left", "padding": "8px", "fontSize": "13px", backgroundColor: '#dddddd', whiteSpace: 'nowrap', fontWeight: 'bold' },

        // cardsServices: { }
    }

    const handleLogin = () => {
        let emailinput = document.querySelector("#email")
        let passwordinput = document.querySelector("#password")
        let data = {
            email: emailinput.value,
            password: passwordinput.value
        }
        api.post('/login', data).then((res) => {

            localStorage.setItem('token', JSON.stringify(res.data.access_token));

            api.defaults.headers.Authorization = `Bearer ${res.data.access_token}`;

            api.post('/me').then((res) => {
                if (res.data.message === 'sucess') {
                    setUser(res.data.usuario.original);
                    console.log(res.data);

                    api.get('listar-informacoes-orcamentos')
                        .then(res => {
                            console.log(res.data);

                            const PendetesFiltrados = res.data.pendetes
                                .sort(({ seq: a }, { seq: b }) => b - a)
                                .filter(({ id_cotacao }, i) => i === res.data.pendetes.findIndex(({ id_cotacao: _id }) => id_cotacao === _id));

                            const EnviadosFiltrados = res.data.enviados
                                .sort(({ seq: a }, { seq: b }) => b - a)
                                .filter(({ id_cotacao }, i) => i === res.data.enviados.findIndex(({ id_cotacao: _id }) => id_cotacao === _id));


                            setDadosTable(PendetesFiltrados);
                            setDadosTableEnviados(EnviadosFiltrados);
                            setDadosTableAceitos(res.data.aceitos);
                            setDadosTableProducao(res.data.producao);
                            setDadosTableCaminho(res.data.caminho);
                            setDadosTableConcluido(res.data.concluido);
                            setDadosTablePerdido(res.data.perdido);
                            setLogado(true);
                        })


                }
            })

        })
        // setLogado(true);

    }

    const clearDados = () => {
        setOrcando(false);
        setUnome('');
        setUsobrenome('');
        setUtelefone('');
        setUemail('');
        setIdorc('');
        setIdCotacao('');
        setUnome('');
        setUsobrenome('');
        setUtelefone('');
        setUemail('');
        setDesconto(0);

    }

    const handleSelectOrcamento = async dadosOrc => {
        setDataOrc(dadosOrc);
        api.get('valores-unitarios-produto/' + dadosOrc.id).then(res => {

            setVlrProdutoUnitario(res.data);
            // setModalValores(true)
        })


        api.get('/calcular-frete/' + dadosOrc.id_cotacao).then(res => {
            setVlrCor(res.data);
            setLoading(false)
        })

        // console.log(dadosOrc.);
        setDG(dadosOrc);

        setIdorc(dadosOrc.id);
        setIdCotacao(dadosOrc.id_cotacao);
        setUnome(dadosOrc.nome);
        setObs(dadosOrc.obs);
        setUsobrenome(dadosOrc.sobrenome);
        setUtelefone(dadosOrc.telefone);
        setUemail(dadosOrc.email);

        let resp = await api.get('/getOrcamento/' + dadosOrc.id_cotacao).then(res => {
            var totalLocal = dadosTabelaOrcamento.reduce(getTotal, 0);

            function getTotal(totalLocal, item) {
                return total + (item.valor_total);
            }

            setTotal(totalLocal);
            console.clear();
            console.log('dtb');
            console.log(res.data);
            setDtb(res.data);
            setOrcando(true);
        })

        setOrcando(true);
    }

    const Modalstyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const ModalstyleImage = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    const CriarUsaurio = () => {
        api.post('/user', { nome: novoNome, email: novoEmail, password: novoSenha }).then(res => {
            alert('Usuario criado');
            setNovoEmail('');
            setNovoNome('');
            setNovoSenha('');
            handleClose();

        }
        ).catch(error => {
            alert('Dados invalidos');
        })
    }

    const HorasPassadas = ({ dados }) => {

        const now = new Date(); // Data de hoje
        const past = new Date(dados.horas); // Outra data no passado
        const diff = Math.abs(now.getTime() - past.getTime()); // Subtrai uma data pela outra
        const minutos = Math.ceil(diff / (1000 * 60)); // Divide o total pelo total de milisegundos correspondentes a 1 dia. (1000 milisegundos = 1 segundo).

        const horas = Math.floor(minutos / 60);
        const min = minutos % 60;
        const textoHoras = (`00${horas}`).slice(-2);
        const textoMinutos = (`00${min}`).slice(-2);

        return (
            <Text style={{ whiteSpace: 'nowrap' }}>{textoHoras} horas e {textoMinutos} minutos atras </Text>
        )
    }

    const CalcularTotal = () => {
        let totalLocal = 0;
        dadosTabelaOrcamento.forEach(dados => {
            totalLocal += dados.valor_total
        });

        return totalLocal;
    }

    const CalcularFrete = () => {
        return (<>
            {loadingValores ? (
                <tr>
                    <th style={style.th}>Carregando valores entrega</th>
                    <th style={style.th}>...</th>
                    <th style={style.th}>...</th>
                    <th style={style.th}>...</th>
                    <th style={style.th}>...</th>
                </tr>
            ) :
                (
                    <tr>
                        <th style={style.th}>Valor Sedex</th>
                        <th style={style.th}>{valorCor.TotalSedex.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</th>
                        <th style={style.th}></th>
                        <th style={style.th}>Valor Pac</th>
                        <th style={style.th}>{valorCor.TotalPac.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</th>
                    </tr>)}
        </>);

    }

    const TableSpace = () => {
        return (<tr>
            <th style={style.th}></th>
            <th style={style.th}></th>
            <th style={style.th}></th>
            <th style={style.th}></th>
            <th style={style.th}></th>
        </tr>)
    }

    const handleVerValores = () => {
        // api.get('valores-unitarios-produto/' + dadosTabelaOrcamento[0].id).then(res => {

        //     setVlrProdutoUnitario(res.data);
        setModalValores(true)
        // })

    }

    const TableDeDados = () => {
        return (
            <table style={style.table}>
                <tr><th style={style.th}>Id</th>
                    <th style={style.th}>Descrição</th>
                    <th style={style.th}>Valor unitário</th>
                    <th style={style.th}>Quantidade</th>
                    <th style={style.th}>Total</th>
                </tr>
                {dadosTabelaOrcamento.map((dados, key) =>
                    <tr>
                        <td style={style.th}>{key + 1}</td>
                        <td style={style.th}>{dados.material} {dados.produto} {dados.tamanho}cm</td>
                        <td style={style.th}>{dados.valor_unitario.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                        <td style={style.th}>{dados.quantidade}</td>
                        <td style={style.th}>{dados.valor_total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                    </tr>
                )}



                <tr>
                    <th style={style.th}></th>
                    <th style={style.th}></th>
                    <th style={style.th}></th>
                    <th style={style.th}>Total</th>
                    <th style={style.th}>{CalcularTotal().toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</th>
                </tr>

                <TableSpace />


                <CalcularFrete />

                <TableSpace />

                <tr>
                    <th style={style.th}>Ver valores unitario</th>
                    <th style={style.th}><Btn primary label="Ver valores" style={{ backgroundColor: '#F3C266', border: 'none' }} color='black' onClick={() => handleVerValores()} /></th>
                    <th style={style.th}></th>
                    <th style={style.th}></th>
                    <th style={style.th}></th>
                </tr>

                <tr>
                    <th style={style.th}>Definir fotos</th>
                    <th style={style.th}><Btn primary label="Ver fotos" style={{ backgroundColor: '#F3C266', border: 'none' }} color='black' onClick={() => setModalImages(!openImages)} /></th>
                    <th style={style.th}></th>
                    <th style={style.th}></th>
                    <th style={style.th}></th>
                </tr>

            </table>
        )
    }

    return (
        <Main>
            <Header pad={{ left: 'xlarge', right: 'xlarge' }} background={{ color: 'rgb(179 179 179)' }}>
                <img src={logo} style={{ height: '10vh', alignSelf: 'center' }} />

                <Stack direction="row" mt={2} spacing={2} sx={{ position: 'relative' }}>

                    <Bt variant="contained" sx={style.navbarAtual} href="https://chilecoins.com.br/orcamento" target="_blank" >Novo Orçamento</Bt>

                    <Bt variant="contained" sx={style.navbarAtual} onClick={() => handleOpen()} >Novo Usuário</Bt>

                    <Bt variant="contained" sx={style.navbarAtual} onClick={() => setOpenProdutos(true)} >Novos Produtos</Bt>
                    <Link to="/orcamento/admin/ajustes" style={{ textDecoration: 'none' }}>
                        <Bt variant="contained" sx={style.navbarAtual} >Ajustes</Bt>
                    </Link>
                    <Bt variant="contained" sx={style.navbarAtual} onClick={() => { navigate('/orcamento/admin/login'); localStorage.clear(); }} >Sair</Bt>
                </Stack>
            </Header>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={Modalstyle}>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={4}
                    >
                        <Text> Novo usuário</Text>
                        <TextInput
                            value={novoNome}
                            placeholder="Nome"
                            onChange={event => setNovoNome(event.target.value)}
                        />

                        <TextInput
                            value={novoEmail}
                            placeholder="Email"
                            onChange={event => setNovoEmail(event.target.value)}
                        />
                        <TextInput
                            value={novoSenha}
                            placeholder="Senha"
                            onChange={event => setNovoSenha(event.target.value)}
                        />
                        <Bt variant="contained" sx={style.navbarAtual} onClick={() => CriarUsaurio()} >Criar Usuário</Bt>
                    </Stack>
                </Box>
            </Modal>

            <Modal
                open={openProdutos}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={Modalstyle}>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={4}
                    >
                        <Text> Novo produto</Text>

                        <Select
                            // placeholder='Produto'
                            options={['Material', 'Esmalte', 'Cor', '3D', 'Borda', 'Gravado', 'Embalagem']}
                            value={testeproduto}
                            onChange={({ option }) => setTesteProduto(option)}
                        />

                        <TextInput
                            // value={novoNome}
                            placeholder="Nome"
                        // onChange={event => setNovoNome(event.target.value)}
                        />

                        <TextInput
                            // value={novoEmail}
                            placeholder="Valor"
                        // onChange={event => setNovoEmail(event.target.value)}
                        />
                        {/* <TextInput
                                    value={novoSenha}
                                    placeholder="Senha"
                                    onChange={event => setNovoSenha(event.target.value)}
                                /> */}
                        <Bt variant="contained" sx={style.navbarAtual} onClick={() => CriarUsaurio()} >Criar Produto</Bt>

                        <Bt variant="contained" sx={style.navbarAtual} onClick={() => setOpenProdutos(false)} >Cancelar</Bt>
                    </Stack>
                </Box>
            </Modal>

            <Modal
                open={openModalValores}
                onClose={() => setModalValores(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={Modalstyle}>
                    {openModalValores == true ? (<table style={style.table}>

                        <tr>
                            <th style={style.th}>Tipo</th>
                            <th style={style.th}>Valor</th>
                            <th style={style.th}>Nome</th>
                        </tr>

                        <tr>
                            <td style={style.th}>{valorProdutoUnitario.produto}</td>
                            <td style={style.th}>R$ 35.00</td>
                            <td style={style.th}></td>
                        </tr>

                        <tr>
                            <td style={style.th}>Diametro</td>
                            <td style={style.th}>{valorProdutoUnitario.diametro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                            <td style={style.th}>{dadosTabelaOrcamento[0].tamanho} cm</td>
                        </tr>

                        <tr>
                            <td style={style.th}>Espessura</td>
                            <td style={style.th}>{dadosTabelaOrcamento[0].largura <= 3 ? "R$ 0,00" : dadosTabelaOrcamento[0].largura <= 3.5 ? "R$ 4,00" : "R$ 8,00"}</td>
                            <td style={style.th}>{dadosTabelaOrcamento[0].largura} mm</td>
                        </tr>

                        <tr>
                            <td style={style.th}>Material</td>
                            <td style={style.th}>{valorProdutoUnitario.material.valor_total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                            <td style={style.th}> {valorProdutoUnitario.material.nome}</td>
                        </tr>

                        <tr>
                            <td style={style.th}>Esmalte</td>
                            <td style={style.th}>{valorProdutoUnitario.esmalte.valor_total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                            <td style={style.th}>{valorProdutoUnitario.esmalte.nome}</td>
                        </tr>

                        <tr>
                            <td style={style.th}>Cor</td>
                            <td style={style.th}>{valorProdutoUnitario.cor.valor_total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                            <td style={style.th}> {valorProdutoUnitario.cor.nome}</td>
                        </tr>

                        <tr>
                            <td style={style.th}>3D</td>
                            <td style={style.th}>{valorProdutoUnitario.u3d.valor_total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                            <td style={style.th}> {valorProdutoUnitario.u3d.nome}</td>
                        </tr>

                        <tr>
                            <td style={style.th}>Borda</td>
                            <td style={style.th}>{valorProdutoUnitario.borda.valor_total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                            <td style={style.th}> {valorProdutoUnitario.borda.nome}</td>
                        </tr>

                        <tr>
                            <td style={style.th}>Gravado</td>
                            <td style={style.th}>{valorProdutoUnitario.gravado.valor_total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                            <td style={style.th}> {valorProdutoUnitario.gravado.nome}</td>
                        </tr>

                        <tr>
                            <td style={style.th}>Embalagem</td>
                            <td style={style.th}>{valorProdutoUnitario.embalagem.valor_total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                            <td style={style.th}> {valorProdutoUnitario.embalagem.nome}</td>
                        </tr>

                        <tr>
                            <td style={style.thW}>Total Unitario:</td>
                            <td style={style.thW}>{valorProdutoUnitario.unitario.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                            <td style={style.thW}></td>


                        </tr>
                    </table>) : ""}

                </Box>
            </Modal>

            {orcando == false
                ? (<TableList data={tableDatum} teste={dadosTable} setDatum={(data) => setDatum(data)} handleSelectOrcamento={(datum) => handleSelectOrcamento(datum)} update={update} setUpdate={setUpdate} />)
                : (<>
                    <Stack justifyContent="center"
                        alignItems="center">


                        <h1>ORÇAMENTO - {idCotacao}</h1>


                        <Bx style={{ width: '30%' }} gap='small'>
                            <InformacoesDoCliente
                                unome={unome}
                                usobrenome={usobrenome}
                                utelefone={utelefone}
                                uemail={uemail}
                                dataOrc={dataOrc}
                                setUnome={setUnome}
                                setUsobrenome={setUsobrenome}
                                setUtelefone={setUtelefone}
                                setUemail={setUemail}
                                uObs={uObs} />

                            <Modal
                                open={openImages}
                                onClose={() => setModalImages(!openImages)}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={ModalstyleImage} >

                                    <Stack
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={4}
                                    >
                                        <ModalDeFotos dadosTabelaOrcamento={dadosTabelaOrcamento} />
                                    </Stack>
                                </Box>
                            </Modal>


                            <TableDeDados />

                            <Stack direction={'column'} justifyContent="left" gap={'20%'} alignItems="center"  >
                                <Text>Desconto</Text>
                                <Stack direction={'row'} justifyContent="center" gap={'1%'}
                                    alignItems="center" >
                                    %  <TextInput
                                        type={'number'}
                                        onChange={event => setDesconto(event.target.value)}
                                    />
                                </Stack>

                                <Text>Valor do desconto</Text>
                                <Stack direction={'row'} justifyContent="center" gap={'1%'}
                                    alignItems="center" >
                                    R$  <TextInput
                                        type={'number'}
                                        value={(CalcularTotal() * (desconto / 100))}
                                    />
                                </Stack>

                                <Text>Valor com desconto</Text>
                                <Stack direction={'row'} justifyContent="center" gap={'1%'}
                                    alignItems="center" >
                                    R$  <TextInput
                                        type={'number'}
                                        value={CalcularTotal() - (CalcularTotal() * (desconto / 100))}
                                    />
                                </Stack>
                            </Stack>



                            <Btn primary label="Enviar" style={{ backgroundColor: '#F3C266', border: 'none' }} onClick={() => handleSendOrcament()} color='black' />
                            <Btn primary label="Cancelar" style={{ backgroundColor: '#B3B3B3', border: 'none', marginBottom: '5%' }} color='black' onClick={() => {
                                clearDados();
                            }} />
                        </Bx>
                    </Stack>
                </>)}








        </Main >
    );
}

export default Login;
