import Home from "./screens/home/home";

function App() {

  return (
    <Home />
  );
}

export default App;
