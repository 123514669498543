
import logo from '../../assets/logo.png'
import React from 'react';
import { Header, Box as Bx, Text, TextInput, Button as Btn, Card, DataTable, Select, Button, Table, TableHeader, TableBody, TableRow, TableCell, ResponsiveContext, Tab, Tabs, FileInput, TextArea, Main } from 'grommet';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Bt from '@mui/material/Button';
import api from '../../services/api'
import { red } from '@mui/material/colors';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import moment from 'moment';

function InformacoesDoCliente({ unome, usobrenome, utelefone, uemail, dataOrc, setUnome, setUsobrenome, setUtelefone, setUemail, uObs }) {
    return (
        <Bx gap='small'>
            <Text> Dados do cliente</Text>
            <Bx direction='row' style={{ width: '100%' }} gap='small'>
                <TextInput
                    value={unome}
                    onChange={event => setUnome(event.target.value)}
                    placeholder="Nome"
                />
                <TextInput
                    value={usobrenome}
                    placeholder="Sobrenome"
                    onChange={event => setUsobrenome(event.target.value)}
                />
            </Bx>
            <TextInput
                value={utelefone}
                placeholder="Telefone: +55 (ddd)"
                onChange={event => setUtelefone(event.target.value)}
            />

            <TextInput
                value={uemail}
                placeholder="Email"
                onChange={event => setUemail(event.target.value)}
            />

            <Bx direction='row' style={{ width: '100%' }} gap='small'>
                <TextInput
                    value={dataOrc.enderecoEntrega_cep}
                // onChange={event => setUemail(event.target.value)}
                />

                <TextInput
                    value={dataOrc.enderecoEntrega_cidade}
                // onChange={event => setUemail(event.target.value)}
                />
            </Bx>
            <Bx direction='row' style={{ width: '100%' }} gap='small'>
                <TextInput
                    value={dataOrc.enderecoEntrega_estado}
                // onChange={event => setUemail(event.target.value)}
                />
                <TextInput
                    value={dataOrc.enderecoEntrega_completomento}
                // onChange={event => setUemail(event.target.value)}
                />
            </Bx>



            <TextArea
                placeholder="Observações"
                value={uObs}
            />
        </Bx>

    )
}

export default InformacoesDoCliente;