
import logo from '../../assets/logo.png'
import React from 'react';
import { Header, Box as Bx, Button as Btn, Card, ResponsiveContext, Main } from 'grommet';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import api from '../../services/api'
import { Link, useNavigate } from "react-router-dom";


const Login = () => {
    const navigate = useNavigate();

    const style = {
        card: {
            left: '30%',
            right: '30%',
            top: 'xlarge'
        },
        cardSmall: {
            left: '2%',
            right: '2%',
            top: 'xlarge'
        },
    }

    const handleLogin = () => {
        let emailinput = document.querySelector("#email")
        let passwordinput = document.querySelector("#password")
        let data = {
            email: emailinput.value,
            password: passwordinput.value
        }
        api.post('/login', data).then((res) => {

            localStorage.setItem('token', JSON.stringify(res.data.access_token));

            api.defaults.headers.Authorization = `Bearer ${res.data.access_token}`;

            navigate('/orcamento/admin')


        })
    }


    React.useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            api.defaults.headers.Authorization = `Bearer ${JSON.parse(token)}`;
            api.post('/me').then((res) => {
                if (res.data.message === 'sucess') {
                    navigate('/orcamento/admin')
                }
            })
        }
    }, [])

    const size = React.useContext(ResponsiveContext);
    return (
        <Main>
            <Header pad={{ left: 'xlarge', right: 'xlarge' }} background={{ color: 'rgb(179 179 179)' }}>
                <img src={logo} style={{ height: '10vh', alignSelf: 'center' }} />


            </Header><Card margin={
                size !== 'small' ? style.card : style.cardSmall}
                background={{ color: 'rgb(179 179 179)' }}
                pad='large'
            >
                <Bx gap='medium'
                    margin={{
                        left: '20%',
                        right: '20%',

                    }}
                >
                    <Stack
                        justifyContent="center"
                        alignItems="center"
                    >

                        <img src={logo} />
                    </Stack>
                    <TextField id="email" label="Email" variant="filled" sx={{ backgroundColor: 'white' }} />

                    <TextField id="password" label="Senha" variant="filled" sx={{ backgroundColor: 'white' }} type={'password'} />

                    <Btn primary label="Entrar" style={{ backgroundColor: '#F3C266', border: 'none' }} color='black' onClick={() => handleLogin()} />
                </Bx>
            </Card>
        </Main>

    );
}

export default Login;
